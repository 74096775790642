.content {
  /* min-height: 100vh; */
  /* padding: 24px; */
}

.company-logo {
  float: left;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 16px; /* Adjust the font size as needed */
  line-height: 40px; /* Adjust the line height to match the height of the buttons */
  color: #fff; /* Set the text color to white */
  margin: 8px 24px 16px 0;
}

.logo-img {
  width: 50px; /* Adjust the width as needed */
  height: auto;
  margin-right: 8px; /* Add some space between the logo and the text */
  vertical-align: middle;
}

.user-menu-button {
  display: block;
  width: 100%;
  text-align: center;
}

.align-top-left {
  vertical-align: top;
  text-align: left;
}

.ant-menu-item.zenpoints-display {
  color: lightgray !important; /* or whatever color your other menu items use */
}
